import useSWRMutation from 'swr/mutation';
import type { Clerk } from '@clerk/types';

declare global {
  interface Window {
    Clerk: Clerk;
  }
}

interface SwrKey {
  url: string;
  method: string;
  body?: any;
}

interface ExtraArg {
  path?: string;
  data?: any;
  conflictError?: string;
}

interface FetchParams {
  url: string;
  method?: string;
  body?: any;
  reloadOnUnauthorized?: boolean;
}

export class MutationError extends Error {
  private _displayErrorMessage: string;
  constructor(error: any, displayErrorMessage: string) {
    super(error);
    this._displayErrorMessage = displayErrorMessage;
  }

  get displayErrorMessage() {
    return this._displayErrorMessage;
  }
}

export class ConflictError extends Error {}

const apiPrefix = '/api';
const unauthKey = 'unauthenticated';

const customFetch = async ({
  url,
  method = 'get',
  body,
  reloadOnUnauthorized = true,
}: FetchParams) => {
  if (body) {
    body = JSON.stringify(body);
  }

  const token = await window.Clerk.session?.getToken();

  if (!token) {
    throw new Error('invalid token');
  }

  const response = await fetch(`${apiPrefix}${url}`, {
    method,
    body,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'omit',
  });

  if (
    reloadOnUnauthorized &&
    response.status === 401 &&
    !sessionStorage.getItem(unauthKey)
  ) {
    sessionStorage.setItem(unauthKey, 'yes');
    return window.location.reload();
  }

  if (!response.ok) {
    const msg = `request failed with status code ${response.status}`;
    if (response.status === 409) {
      throw new ConflictError(msg);
    }
    throw new Error(msg);
  }

  sessionStorage.removeItem(unauthKey);

  const contentType = response.headers.get('content-type');

  if (contentType?.includes('application/json')) {
    return response.json();
  }
};

export const fetcher = (param: string | FetchParams) => {
  if (typeof param === 'string') {
    return customFetch({ url: param });
  }
  return customFetch(param);
};

export const mutateFetcher = async (
  key: SwrKey,
  { arg }: Readonly<{ arg: ExtraArg }>,
) => {
  try {
    const { url } = key;
    const actualURL = [url, arg.path].filter((item) => item).join('/');
    return await customFetch({
      url: actualURL,
      method: key.method,
      body: arg.data,
    });
  } catch (error) {
    let msg = 'Error saving data';
    if (error instanceof ConflictError && arg.conflictError) {
      msg = arg.conflictError;
    }
    throw new MutationError(error, msg);
  }
};

export const useSWRMutationTyped = <Data>(key: SwrKey) => {
  return useSWRMutation<Data, any, SwrKey, ExtraArg>(key, mutateFetcher as any);
};
