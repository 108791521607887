import { forwardRef } from 'react';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import '@fontsource/roboto/latin-300.css';
import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-500.css';
import '@fontsource/roboto/latin-700.css';

// eslint-disable-next-line react/display-name
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const theme: ThemeOptions = {
  palette: {
    mode: 'light',
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none',
        component: LinkBehavior,
      },
    },
    MuiStack: {
      defaultProps: {
        sx: {
          mb: 8,
        },
        useFlexGap: true,
      },
    },
  },
};

// Create a theme instance.
export const lightTheme = createTheme(theme);

export const darkTheme = createTheme({
  ...theme,
  palette: {
    mode: 'dark',
  },
});
