import { Alert, AlertColor, Snackbar as MuiSnackbar } from '@mui/material';
import React from 'react';

export interface Props {
  open: boolean;
  severity?: AlertColor;
  message?: React.ReactNode;
  onClose?: () => any;
}

export default function Snackbar({ severity, message, onClose, open }: Props) {
  const handleClose = (event: any, reason?: any) => {
    if (onClose && reason !== 'clickaway') {
      onClose();
    }
  };

  const isOpen = !!message && !!severity && open;

  let autoHideDuration: number | null = null;
  if (severity !== 'error') {
    autoHideDuration = 6_000;
  }

  return (
    <MuiSnackbar
      open={isOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert severity={severity} variant="filled" onClose={handleClose}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
